/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useState } from "react";
import tw, { css, theme } from "twin.macro";
import * as T from "~components/styles/Typography.jsx";

const Button = ({
  _css = [],
  color = `white`,
  smallText,
  onClick,
  text,
  transparent,
  disabled = false
}) => {
  let borderColor;
  let textColor;
  let bgColor;

  if (color && theme`colors`?.[color]) {
    if (!transparent) {
      bgColor = theme`colors`?.[color];
      textColor = theme`colors.white`;
      borderColor = theme`colors`?.[color];
    } else {
      bgColor = theme`colors.transparent`;
      textColor = theme`colors`?.[color];
      borderColor = theme`colors`?.[color];
    }
  }

  //

  return (
    <button
      type="button"
      css={[
        _css,
        css`
          background-color: ${bgColor};
          color: ${textColor};
          border: 1px solid ${borderColor};
          border-radius: 4px;
        `,
        tw`relative block font-head w-full md:w-auto transition-colors duration-300`,
        transparent ? tw`pt-3 pb-2 px-5` : tw`pt-3 md:pt-2 pb-2 md:pb-1 px-20`
      ]}
      onClick={onClick}
      disabled={disabled}
    >
      <T.Button
        _css={[
          css`
            :hover {
              transform: scale(1.04);
            }
          `,
          smallText &&
            css`
              font-size: 16px !important;
              padding: 0 !important;
              line-height: 32px !important;
            `,
          tw`relative block transition-transform duration-300`,
          transparent
            ? tw`text-button md:text-button-md`
            : tw`text-button-md md:text-h4-md`
        ]}
      >
        {text}
      </T.Button>
    </button>
  );
};

export default Button;
